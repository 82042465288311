import './mobile.css';
import insta from '../../logo/ig.svg';
import whats from '../../logo/whatsapp.svg';
import mail from '../../logo/mail.svg';
import LinkWithImage from '../../components/LinkWithImage';
import text from '../../texts/texts.json';
import ContactCarousel from '../../components/ContactCarousel';
import { Link } from 'react-router-dom';
import Navbar_mobile from '../../components/Navbar_mobile';

import img1 from '../../images/Studio/Studio4_scelta.png';
import img3 from '../../images/Studio/Studio5_scelta.png';
import img4 from '../../images/Studio/Studio6.jpeg';

import img8 from '../../images/Studio/Indicazioni_alto.png';
import img9 from '../../images/Studio/Indicazionisx.jpeg';
import img10 from '../../images/Studio/Indicazioni2.jpeg';
import img11 from '../../images/Studio/Inidcazionilatoopposto.jpeg';


const link_whats = 'https://wa.me/+393403595298';
const link_insta = 'https://www.instagram.com/chieri_wakabayashi/';
const link_mail = "https://mail.google.com/mail/?view=cm&fs=1&to=chieriwak@gmail.com&su=Oggetto&body=Corpo%20del%20messaggio";

function Contatti_mobile() {
    const images = [img1, img3, img4];
    const images2 = [img8, img9, img10, img11 ];
    return (
        // Layout Mobile <= 1024px
        <div className='mobile'>
            <div className='content_contatti_sub_mobile'>
                <Navbar_mobile></Navbar_mobile>
                <div className="contact-info">
               
                    <p>
                        Puoi trovarmi presso il mio studio in:
                    </p>
                    <Link to={`https://maps.google.com/?q=${text.contatti_diretti.indirizzo.via} ${text.contatti_diretti.indirizzo.cap} ${text.contatti_diretti.indirizzo.città}`} className="footer-link">
                        📍 {text.contatti_diretti.indirizzo.via} {text.contatti_diretti.indirizzo.cap} {text.contatti_diretti.indirizzo.città}
                    </Link>
                   
                </div>
                <ContactCarousel images={images2} />
                <div className='contact-info'>
                    <p>All'interno troverai un uno spazio progettato con cura per offrire un ambiente di puro relax. Ogni dettaglio, dalla scelta degli arredi alla disposizione degli spazi, è stato pensato per favorire il benessere e creare l'atmosfera ideale per il massaggio Shiatsu, dove ogni trattamento è un'esperienza di tranquillità e rigenerazione.</p>
                   
                </div>
                <ContactCarousel images={images} />

                <div className='information'>
                    <p>Per informazioni e prenotazioni puoi contattarmi al:
                    </p>
                    <Link to="tel:+393403595298" >+393403595298</Link>

                </div>

                <div className="social-icons">
                    <LinkWithImage imageSrc={whats} altText="Whatsapp" linkUrl={link_whats} />
                    <LinkWithImage imageSrc={insta} altText="Instagram" linkUrl={link_insta} />
                    <LinkWithImage imageSrc={mail} altText="Gmail" linkUrl={link_mail} />
                </div>

                <div className='footer'>
                    <footer className='footer'>
                        <Link to={`https://maps.google.com/?q=${text.contatti_diretti.indirizzo.via} ${text.contatti_diretti.indirizzo.cap} ${text.contatti_diretti.indirizzo.città}`} className="footer-link">
                            📍 {text.contatti_diretti.indirizzo.via} {text.contatti_diretti.indirizzo.cap} {text.contatti_diretti.indirizzo.città}
                        </Link>
                        <Link to={`tel:${text.contatti_diretti.telefono}`} className="footer-link">
                            📞 {text.contatti_diretti.telefono}
                        </Link>
                        <Link to={`mailto:${text.contatti_diretti.email}`} className="footer-link">
                            ✉️ {text.contatti_diretti.email}
                        </Link>
                        <p>P.IVA: {text.contatti_diretti.partita_iva}</p>
                    </footer>
                </div>

            </div>
        </div>

    );
}
export default Contatti_mobile;