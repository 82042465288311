
import '../css/Home.css';
import texts from '../../texts/texts.json';
import Navbar_mobile from '../../components/Navbar_mobile';
import Logo from '../../components/Logo';


function Home_mobile() {
    return (
        <>
            <section className="hero">
                <div className='content'>
                    <div className='header'>
                        <Navbar_mobile theme="dark" />
                    </div>
                    <div className='body'>
                        <div className='body--logo'>
                            <Logo />
                        </div>
                        <h1 className='body--title'> {texts.title} </h1>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Home_mobile;

