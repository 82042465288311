import React from 'react';
import '../pages/css/Contatti.css';

const LinkWithImage = ({ imageSrc, altText, linkUrl, textLabel, width = '50px', height = '50px' }) => {
  return (
    <div className='link_box'>
      <a href={linkUrl} target="_blank" rel="noopener noreferrer">
        <img className='icons'
          src={imageSrc}
          alt={altText}
          style={{ width: width, height: height }} // Dimensioni impostabili via props
        />
      </a>
      <p>{textLabel}</p>
    </div>
  );
};

export default LinkWithImage;
