import Carousel from 'react-bootstrap/Carousel';
import img1dsk from '../images/Carousel/DSC_2495.jpeg';
import img2dsk from '../images/Carousel/DSC_2540.jpg';
import text from '../texts/texts.json';
import AboutMe from '../pages/Aboutme';
import '../pages/css/Abcarousel.css';
import { useState, useEffect } from 'react';



const testi1 = [
    "Cos'é lo Shiatsu?",
    text.lavoro.descrizione_shiatsu,
    text.lavoro.descrizione_digitopressione
];
const testi2 = [
    "I benefici", // Titolo
    "", // Sottotitolo vuoto
    [
        text.lavoro.benefici_shiatsu, // Stringa introduttiva
        ...text.lavoro.benefici_shiatsu_elenco, // Array dei benefici, descritto correttamente
        text.lavoro.benefici_shiatsu_elenco_end, // Stringa conclusiva
    ],
];

const img = [
    img1dsk,
];

const img2 = [
    img2dsk
];

function ShiatsuCarousel() {

    return (
        <>
                <div>
                    <Carousel interval={null}>
                        <Carousel.Item>
                            <AboutMe images={img} text={testi1} />
                        </Carousel.Item>

                        <Carousel.Item>
                            <AboutMe images={img2} text={testi2} />
                        </Carousel.Item>

                    </Carousel>
                </div>
        </>


    );
}

export default ShiatsuCarousel;