import './App.css';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import Home from './pages/Home';
import Home_mobile from './pages/mobile/Home_mobile';
import About_mobile from './pages/mobile/About_mobile';
import Contatti from './pages/mobile/Contatti_mobile';
import Work_mobile from './pages/mobile/Work_mobile';

const sectionMap = {
  '/': 'hero',
  '/chisono': 'chisono',
  '/shiatsu': 'shiatsu',
  '/contatti': 'contatti',
};

function MobileRoutes() {
  const location = useLocation();

  useEffect(() => {
    // Ensure page scrolls to top when navigating to a new route
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [location.pathname]); // Runs whenever the path changes

  return (
    <div>
      <Routes location={location}>
        <Route index element={<Home_mobile />} />
        <Route path="/chisono" element={<About_mobile />} />
        <Route path="/shiatsu" element={<Work_mobile />} />
        <Route path="/contatti" element={<Contatti />} />
      </Routes>
    </div>
  );
}

function ResponsiveRoutes({ isDesktop }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop) {
      const sectionId = sectionMap[location.pathname];
      if (sectionId) {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
        if (location.pathname !== '/') { // Redirect only if we're not already on the home page
          navigate('/'); // Redirect to the home page in desktop mode
        }
      }
    } else {
      const mobilePath = Object.keys(sectionMap).find(
        path => sectionMap[path] === location.hash.substring(1)
      );
      if (mobilePath && location.pathname !== mobilePath) { // Avoid redirection loops
        navigate(mobilePath); // Redirect to the correct mobile page
      }
    }
  }, [isDesktop, location.pathname, navigate]); // Only run when isDesktop or path changes

  return (
    <Routes>
      {isDesktop ? (
        <Route index element={<Home />} />
      ) : (
        <Route path="/*" element={<MobileRoutes />} />
      )}
    </Routes>
  );
}

export default function App() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth > 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <BrowserRouter>
      <ResponsiveRoutes isDesktop={isDesktop} />
    </BrowserRouter>
  );
}
