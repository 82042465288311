import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink, useLocation } from 'react-router-dom'; // Importa useLocation per ottenere il percorso
import "./Navbar.css";
import texts from '../texts/texts.json';

function Navbar_mobile({ theme }) {
    const navRef = useRef();
    const location = useLocation(); // Ottieni l'oggetto della location (URL)

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

    const closeNavbarOnClick = () => {
        navRef.current.classList.remove("responsive_nav");
    };

    // Determina se siamo sulla Home o su un'altra pagina
    const isHomePage = location.pathname === "/";

    return (
        <header className={`navbar-${theme} ${isHomePage ? 'navbar--transparent' : 'navbar--solid'}`}>
            <button className="nav-btn nav-btn--burger" onClick={showNavbar}>
                <FaBars />
            </button>
            <nav ref={navRef}>
                <NavLink to="/" onClick={closeNavbarOnClick}>
                    HOME
                </NavLink>
                <NavLink to="/chisono" onClick={closeNavbarOnClick}>
                    CHI SONO
                </NavLink>
                <NavLink to="/shiatsu" onClick={closeNavbarOnClick}>
                    SCOPRI LO SHIATSU
                </NavLink>
                <NavLink to="/contatti" onClick={closeNavbarOnClick}>
                    CONTATTI
                </NavLink>
                <button className="nav-btn nav-btn--close" onClick={showNavbar}>
                    <FaTimes />
                </button>
            </nav>
        </header>
    );
}

export default Navbar_mobile;
