import './css/Aboutme.css';
import React from 'react';

function AboutMe({ text, images }) {
    // Funzione per gestire la visualizzazione del testo con primo elemento, lista, e ultimo elemento
    const renderTextWithList = (textArray) => {
        if (!Array.isArray(textArray)) return <p>{textArray}</p>;

        return (
            <>
                <p>{textArray[0]}</p>
                <ul>
                    {textArray.slice(1, -1).map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
                <p>{textArray[textArray.length - 1]}</p>
            </>
        );
    };

    return (
        <>
            <div className='content_ab'>
                <div className='body_ab'>
                    <div className='box_ab'>
                        <div className='text_box_title'>
                            <h1>{text[0]}</h1>
                            <h3>{text[1]}</h3>
                        </div>
                    </div>
                    {images[0] && (
                        <div className='box_ab'>
                            <img src={images[0]} alt="chieri" className='img_30' />
                            <div className='text_box'>{renderTextWithList(text[2])}</div>
                        </div>
                    )}
                    {images[1] && (
                        <div className='box_ab'>
                            <div className='text_box'>{renderTextWithList(text[3])}</div>
                            <img src={images[1]} alt="chieri info" className='img_30' />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AboutMe;
