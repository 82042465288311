import '../pages/css/Contatti.css';


function CardContact(prop) {

    return (
        <div className='content_card'>
            <img alt='place' src={prop.image}></img>
        </div>

    );
}

export default CardContact;