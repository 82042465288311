import './mobile.css';
import text from '../../texts/texts.json';
import Navbar_mobile from '../../components/Navbar_mobile';
import Box_content from './Component_mobile/Box_content';
import img1dsk from '../../images/Carousel/DSC_2495.jpeg';
import img2dsk from '../../images/Carousel/DSC_2540.jpg';

const testi1 = [
    "Cos'é lo Shiatsu?",
    text.lavoro.descrizione_shiatsu,
    text.lavoro.descrizione_digitopressione
];
const testi2 = [
    "I benefici", // Titolo
    "", // Sottotitolo vuoto
    [
        text.lavoro.benefici_shiatsu, // Stringa introduttiva
        ...text.lavoro.benefici_shiatsu_elenco, // Array dei benefici, descritto correttamente
        text.lavoro.benefici_shiatsu_elenco_end, // Stringa conclusiva
    ],
];

const img = [
    img1dsk,
];

const img2mobile = [
    img2dsk
];


function Work_mobile() {
    return (
        <div className='mobile'>
            <div className='content_ab'>
                <Navbar_mobile />
                <div className='body_ab'>
                    <Box_content text={testi1} images={img} />
                    <Box_content text={testi2} images={img2mobile} />
                </div>

            </div>
        </div>
    );
}
export default Work_mobile;