import Carousel from 'react-bootstrap/Carousel';
import CardContact from './CardContact.js';

function ContactCarousel({ images }) {
    return (
        <div>
            <Carousel interval={null}>
                {images && images.length > 0 ? (
                    images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <CardContact image={image} />
                        </Carousel.Item>
                    ))
                ) : (
                    <p>No images available</p>
                )}
            </Carousel>
        </div>
    );
}


export default ContactCarousel;
