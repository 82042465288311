import React, { useState, useEffect } from 'react';
import './css/Home.css';
import texts from '../texts/texts.json';
import Navbar from '../components/Navbar';
import Logo from '../components/Logo';
import ShiatsuCarousel from '../components/ShiatsuCarousel';
import './css/Carousel.scss';
import Contatti from './Contatti';
import AboutMeCarousel from '../components/AboutMeCarousel';

function Home() {
    return (
        <>
            <section className="hero">
                <div className='content'>
                    <div className='header'>
                        <Navbar theme="dark" />
                    </div>
                    <div className='body'>
                        <div className='body--logo'>
                            <Logo />
                        </div>
                        <h1 className='body--title'> {texts.title} </h1>
                    </div>
                </div>
            </section>
            <section id="chisono">
                <AboutMeCarousel />
            </section>
            <section id="shiatsu">
                <ShiatsuCarousel />
            </section>
            <section id="contatti">
                <Contatti />
            </section>
        </>
    );
}

export default Home;
